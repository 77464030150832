.app > * {
  background: #2d2026;
  color: #000;
}

.app .pane-1 {
  background-color: rgba(211, 209, 209, 0.101);
  min-width: 350px;
  z-index: 1;
}

.app .pane-2 {
  background-color: #fff;
  padding: 5em;
}

.app .editor-overlay {
  border: 1px solid red;
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("./assets/images/background.jpg");
  background-size: cover;
  background-position: center;
}

.app .editor-overlay.no-image {
  background-image: url("./assets/images/checkboard.png");
  background-size: 10%;
  background-repeat: repeat;
}

.app .editor-overlay .editor-overlay-preview {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.vercel-banner {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
}
